// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Отключение горизонтальной прокрутки */
  width: 100%; /* Ограничение ширины */
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

.content {
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB,EAAE,wCAAwC;EAC5D,WAAW,EAAE,uBAAuB;AACtC;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: content-box;\n}\n\nbody, html {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden; /* Отключение горизонтальной прокрутки */\n  width: 100%; /* Ограничение ширины */\n}\n\nbody {\n  font-family: Arial, sans-serif;\n  background-color: #f5f5f5;\n  color: #333;\n  line-height: 1.6;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n.content {\n  padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
