// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/loupe.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  position: relative;
  width: 100%; 
  height: 40px;
  box-sizing: border-box;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 10px 40px;
  font-size: 18px;
  font-family: system-ui;
  border: 1px solid #dfdfdf;
  outline: none;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #000000;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-size: contain; 
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  cursor: pointer;
}

.search-bar:hover .search-input {
  border-color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/search_bar/searchBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,4BAA4B;EAC5B,yDAA+C;EAC/C,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".search-bar {\n  position: relative;\n  width: 100%; \n  height: 40px;\n  box-sizing: border-box;\n}\n\n.search-input {\n  width: 100%;\n  height: 100%;\n  padding: 10px 40px;\n  font-size: 18px;\n  font-family: system-ui;\n  border: 1px solid #dfdfdf;\n  outline: none;\n  box-sizing: border-box;\n}\n\n.search-input:focus {\n  border-color: #000000;\n}\n\n.search-icon {\n  position: absolute;\n  left: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 18px;\n  height: 18px;\n  background-size: contain; \n  background-repeat: no-repeat;\n  background-image: url('../../images/loupe.svg');\n  cursor: pointer;\n}\n\n.search-bar:hover .search-input {\n  border-color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
