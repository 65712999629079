// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-button {
  padding: 3px 10px;
  height: 25px;
  font-size: 15px;
  display: flex;
  justify-content: center; /* Horizontal position */
  align-items: center; /* Vertical position */
  cursor: pointer;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
}

.default-button.white {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
}

.default-button.dark {
  background-color: #4d4d4d;
  color: #fff;
}

.default-button.white:hover {
  background-color: #e7e7e7;
}

.default-button.dark:hover {
  background-color: #535353;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/button.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,eAAe;EACf,6BAA6B;EAC7B,uDAAuD;EACvD,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".default-button {\n  padding: 3px 10px;\n  height: 25px;\n  font-size: 15px;\n  display: flex;\n  justify-content: center; /* Horizontal position */\n  align-items: center; /* Vertical position */\n  cursor: pointer;\n  border: 2px solid transparent;\n  transition: background-color 0.3s ease, color 0.3s ease;\n  box-sizing: border-box;\n}\n\n.default-button.white {\n  background-color: #fff;\n  color: #000;\n  border: 1px solid #ccc;\n}\n\n.default-button.dark {\n  background-color: #4d4d4d;\n  color: #fff;\n}\n\n.default-button.white:hover {\n  background-color: #e7e7e7;\n}\n\n.default-button.dark:hover {\n  background-color: #535353;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
