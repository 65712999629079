// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.logo h1 {
  margin: 0;
  font-size: 24px;
}

.nav a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
}

.nav a:hover {
  color: #007BFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 20px;\n  background-color: #fff;\n  border-bottom: 1px solid #ddd;\n}\n\n.logo h1 {\n  margin: 0;\n  font-size: 24px;\n}\n\n.nav a {\n  margin: 0 10px;\n  text-decoration: none;\n  color: #333;\n}\n\n.nav a:hover {\n  color: #007BFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
